import React, { useState, useContext, useCallback } from 'react';
import _ from 'lodash';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { wizardProps } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import { ModalNext } from '@jutro/components';
import { useValidation } from 'gw-portals-validation-react';
import { useCurrency } from 'nfum-portals-utils-react';
import useTagManager from '../../../hooks/useTagManager';
import metadata from './ValuablesModal.metadata.json5';
import styles from './ValuablesModal.module.scss';
import messages from './ValuablesModal.messages';

function ValuablesModal(props) {
    const {
        isOpen,
        onResolve,
        initialFormData = {},
        wizardData: submissionVM,
        singleArticleLimit,
        isEditMode,
        viewModelService
    } = props;
    const translator = useContext(TranslatorContext);
    const [form, setForm] = useState(initialFormData);

    const { isComponentValid, onValidate } = useValidation('ValuablesModal');
    const [isReplacementCostFormatValid, setIsReplacementCostFormatValid] = useState(true);
    const currencyFormatter = useCurrency();
    const TEXT_FIELD_MAX_LENGTH = 255;
    const NUMERIC_FIELD_MAX_LENGTH = 16;
    const DIGITS_REGEXP = '^[0-9,.]+$|^$';
    const { pushLinkClickInfo } = useTagManager();

    const getAvailableValuableTypes = useCallback(() => {
        const specifiedValuableTypelist = viewModelService.getTypelistNFUM('pc', 'typekey.SpecifiedValuableType_NFUM');
        return specifiedValuableTypelist.filters.find((x) => x.name === 'PolicyCenterUITypecodes').codes.map((typecode) => ({
            code: typecode.code,
            name: translator({
                id: typecode.name,
                defaultMessage: typecode.code
            })
        }));
    }, [viewModelService, translator]);

    const mapGeoLimit = (item) => {
        const displayItemValue = item === 'AtHomeOnly' ? translator(messages.insideHomeOnly) : translator(messages.bothInsideOutsideHome);
        return {
            code: item,
            name: {
                id: `quoteandbind.ngh.views.valuables.valuableCoverType.${item}`,
                defaultMessage: displayItemValue,
            }
        };
    };

    const writeValue = useCallback((value, path) => {
        setForm({ ...form, [path]: value });
    }, [form]);

    const handleClose = (data) => () => {
        document.activeElement.focus();
        onResolve(data);
    };

    const isValuableBelowLimit = () => {
        const replacementCost = _.get(form, 'replacementCost');
        const limit = parseInt(singleArticleLimit.replace(',', ''), 10);
        return replacementCost <= limit;
    };

    const getValuablesReplCostValidationMessages = () => {
        let validationMessages = [];
        if (isValuableBelowLimit()) {
            validationMessages = [
                ...validationMessages,
                translator(messages.singleArticleLimitValidationMessage, { limit: `£${singleArticleLimit}` })
            ];
        }
        if (!isReplacementCostFormatValid) {
            validationMessages = [
                ...validationMessages,
                translator(messages.formatNotValid)
            ];
        }
        return validationMessages;
    };

    const isDigitsOnly = useCallback((value) => {
        const regex = new RegExp(DIGITS_REGEXP);
        return regex.test(value);
    }, []);

    const validateCharacters = useCallback((value, path) => {
        if (!isDigitsOnly(value)) return;
        writeValue(value, path);
    }, [writeValue, isDigitsOnly]);

    const validateFormat = useCallback((value) => {
        if (!currencyFormatter.isValidFormat(value)) {
            setIsReplacementCostFormatValid(false);
        } else {
            setIsReplacementCostFormatValid(true);
        }
    }, [currencyFormatter]);

    const openFullList = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/help-and-advice/calculating-home-contents/';
        pushLinkClickInfo(translator(messages.fullListHere), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const overrideProps = {
        costLimit: {
            content: `£${singleArticleLimit}`
        },
        valuableType: {
            availableValues: getAvailableValuableTypes(),
            disabled: isEditMode
        },
        valuableCoverType: {
            availableValues: _.get(submissionVM, 'lobData.homeLine.lineCoverages.geoLimit.value')
                .map(mapGeoLimit),
        },
        valuableDescription: {
            tooltip: {
                text: translator(messages.valuableDescriptionTooltip)
            },
            maxLength: TEXT_FIELD_MAX_LENGTH
        },
        valuableReplacementCost: {
            tooltip: {
                text: translator(messages.valuableReplacementCostTootltip)
            },
            onValueChange: validateCharacters,
            onBlur: validateFormat,
            validationMessages: getValuablesReplCostValidationMessages(),
            maxLength: NUMERIC_FIELD_MAX_LENGTH
        },
        valuableStorageType: {
            tooltip: {
                text: translator(messages.valuableStorageTypeTooltip)
            }
        },
        saveAndAddButton: {
            disabled: !isReplacementCostFormatValid
                || isValuableBelowLimit()
                || !isComponentValid
        },
        addItemButton: {
            disabled: !isReplacementCostFormatValid
            || isValuableBelowLimit()
            || !isComponentValid
        },
        fullListHere: {
            onClick: openFullList
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddItem: handleClose({ type: 'ADD', data: form }),
            onSaveAndAdd: handleClose({ type: 'REOPEN', data: form }),
            onCancel: handleClose({ type: 'CANCEL' }),
        }
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={() => handleClose({ type: 'CANCEL' })}
        >
            <ViewModelForm
                className={styles.modalWrapper}
                model={form}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </ModalNext>
    );
}

ValuablesModal.propTypes = wizardProps;
export default ValuablesModal;
