import React, {
    useCallback, useState, useEffect, useContext, useMemo
} from 'react';
import _ from 'lodash';
import {
    ViewModelServiceContext,
    ViewModelForm,
} from 'gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { NfumStepperField } from 'nfum-components-platform-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import {
    useErrorHandler,
    AppContext,
    QB_STEPS,
    useCurrency
} from 'nfum-portals-utils-react';
import commonMessages from '../../NGHWizard.messages';
import useTagManager from '../../hooks/useTagManager';
import NGHContext from '../../NGHContext';
import useCleanPayload from '../../hooks/useCleanPayload';

import styles from './QuotePage.module.scss';
import messages from './QuotePage.messages';
import metadata from './QuotePage.metadata.json5';

import SystemDownMessage from '../../components/SystemDownMessages/SystemDownMessage';

const homeEmergencyCoverUrl = 'https://www.nfumutual.co.uk/home-insurance/home-emergency-cover/';
const contAccidentCovUrl = 'https://www.nfumutual.co.uk/home-insurance/contents-insurance/accidental-damage/';
const buildingAccCovUrl = 'https://www.nfumutual.co.uk/home-insurance/buildings-insurance/accidental-damage/';
const standardLegalExpensesCoverUrl = 'https://www.nfumutual.co.uk/home-insurance/personal-legal-expenses/';
const enhancedLegalExpensesCoverUrl = 'https://www.nfumutual.co.uk/home-insurance/personal-legal-expenses-extra/';
const cyclingProtectionCoverUrl = 'https://www.nfumutual.co.uk/home-insurance/cycling-protection/';


function Cell({ data }) {
    return (
        <span className={styles.tableCell}>
            {data.value}
        </span>
    );
}

function MoneyCell({ data }) {
    return (
        <span className={styles.tableCell}>
            £
            {data.value}
        </span>
    );
}

function StepperCell(props) {
    const { wizardData: submissionVM, updateWizardData, data } = props;
    const viewModelService = useContext(ViewModelServiceContext);

    const findOptionCode = useCallback(
        (name) => data.options.find((item) => item.name === name).code,
        [data.options]
    );

    const handleValueChange = useCallback(
        (value) => {
            const currentOption = data.options.findIndex(((opt) => opt.name === data.value));
            let newValue = data.value;
            if (value > data.value.replaceAll(',', '') && currentOption !== data.options.length - 1) {
                newValue = data.options[currentOption + 1].name;
            } else if (value < data.value.replaceAll(',', '') && currentOption !== 0) {
                newValue = data.options[currentOption - 1].name;
            }
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, `${data.lobPath}.chosenTerm`, findOptionCode(newValue));
            _.set(newSubmissionVM, `${data.lobPath}.chosenTermValue`, newValue);
            _.set(newSubmissionVM, `${data.lobPath}.updated`, true);
            _.set(newSubmissionVM, `${data.lobMainPath}.updated`, true);
            updateWizardData(newSubmissionVM);
        },
        [
            data,
            findOptionCode,
            submissionVM,
            updateWizardData,
            viewModelService
        ]
    );

    return (
        <div className={styles.selectCell}>
            <div className={styles.selectCellDropdown}>
                <NfumStepperField
                    autoTrim={false}
                    shouldShowState={false}
                    dataPath={data.path}
                    minValue={data.options && data.options[0].name}
                    maxValue={data.options && data.options[data.options.length - 3].name.replaceAll(',', '')}
                    dataType="string"
                    hideLabel
                    id="excessDropdownSelect"
                    value={data?.value?.replaceAll(',', '')}
                    onValueChange={handleValueChange}
                />
            </div>
        </div>
    );
}

function SumCell(props) {
    const { wizardData: submissionVM, data } = props;
    const value = _.get(submissionVM, `${data.lobPath}.chosenTermValue`);
    return (
        <span className={styles.tableCell}>
            £
            {Number(value || 0)}
        </span>
    );
}

const generateLineCoveragesPath = (submissionVM) => (lobPath) => (coverage) => {
    const lobCoverages = _.get(submissionVM, lobPath);
    if (lobCoverages) {
        const lobCoverageIndex = lobCoverages?.findIndex(
            (lobCoverage) => lobCoverage.name === coverage || lobCoverage.publicID === coverage
        );
        return `${lobPath}[${lobCoverageIndex}]`;
    }

    return lobPath;
};

const mapHighValueItemsRowData = ({ itemData }, scheduleIndex) => {
    const category = itemData.ArticleValuationMethod.typeCodeValue;
    const description = itemData.ArticleType.typeCodeValue;
    const value = itemData.ArticleLimit.bigDecimal;
    const insideOutside = itemData.ArticleGeographicLimit.typeCodeValue;
    return {
        id: `${scheduleIndex}-${category}`,
        category: { value: category },
        description: { value: description },
        value: { value, valueType: 'money' },
        insideOutside: { value: insideOutside }
    };
};

const hasCoverage = (submissionVM, coverage) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)(coverage);
    const cov = _.get(submissionVM, lobCoveragePath);
    return cov !== undefined;
};

const generateExcessTermRowData = (submissionVM, type) => {
    if (type === 'Compulsory') {
        return [{
            type: { value: type },
            contentsExcess: { value: 100, valueType: 'money' },
            buildingsExcess: { value: 100, valueType: 'money' }
        }];
    }
    if (type === 'Voluntary') {
        const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
        const contentsCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('Contents');
        const buildingsCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('Buildings');
        const buildingsExcessTermPath = generateLineCoveragesPath(submissionVM)(`${buildingsCoveragePath}.terms`)('Voluntary Excess');
        const buildingsExcessTerm = _.get(submissionVM, buildingsExcessTermPath);
        const contentsExcessTermPath = generateLineCoveragesPath(submissionVM)(`${contentsCoveragePath}.terms`)('Voluntary Excess');
        const contentsExcessTerm = _.get(submissionVM, contentsExcessTermPath);
        return [{
            type: { value: type },
            contentsExcess: {
                value: contentsExcessTerm && contentsExcessTerm.chosenTermValue,
                options: contentsExcessTerm && contentsExcessTerm.options,
                valueType: 'stepper',
                lobPath: contentsExcessTermPath,
                lobMainPath: contentsCoveragePath
            },
            buildingsExcess: {
                value: buildingsExcessTerm && buildingsExcessTerm.chosenTermValue,
                options: buildingsExcessTerm && buildingsExcessTerm.options,
                valueType: 'stepper',
                lobPath: buildingsExcessTermPath,
                lobMainPath: buildingsCoveragePath
            },
        }];
    }
    return [];
};

const generateTotalExcessTermRowData = (excessTerms, type) => {
    return [{
        type: { value: type },
        contentsExcess: { value: excessTerms.reduce((a, b) => { return a + Number(`${b.contentsExcess.value}`.replaceAll(',', '')); }, 0), valueType: 'money' },
        buildingsExcess: { value: excessTerms.reduce((a, b) => { return a + Number(`${b.buildingsExcess.value}`.replaceAll(',', '')); }, 0), valueType: 'money' }
    }];
};

const generateHighValueItemsRowData = (submissionVM) => {
    const lobSchedulesPath = 'lobData.homeLine.lineCoverages.schedules.value';
    const lobScheduleItemsPath = generateLineCoveragesPath(submissionVM)(lobSchedulesPath)('ScheduleItems');
    const lobScheduleItems = _.get(submissionVM, `${lobScheduleItemsPath}.scheduleItems`);
    if (lobScheduleItems) {
        return lobScheduleItems.map(mapHighValueItemsRowData);
    }
    return [];
};

const generateAccidentalCoverPath = (submissionVM, coverage, term) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)(coverage);
    const lobAccidentalCoverPath = generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)(term);
    return lobAccidentalCoverPath;
};

const generateBicycleExtensionCoverPath = (submissionVM) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('HOMContentsAwayCov');
    const lobPedalCycleExtensionPath = generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)('HOMContentsAwayCovPedalCycleExtension');
    return { lobCoveragePath, lobPedalCycleExtensionPath };
};

const cellRenderers = {
    money: MoneyCell,
    stepper: StepperCell,
    sum: SumCell,
};
function QuotePage(props) { /* NOSONAR: pure declarative usage */
    const LEGAL_EXPENSES_STANDARD_KEY = 'Standard';
    const LEGAL_EXPENSES_EXTRA_KEY = 'Extra';
    const TIER_LEVEL_THREE_KEY = 'tierLevel3';
    const {
        wizardData: submissionVM,
        updateWizardData,
        steps,
        jumpTo
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const [disablePreviousButton, setDisablePreviousButton] = useState(false);
    const [isLexStandardCover, setIsLexStandardCover] = useState(false);
    const [isLexAllRisksCover, setIsLexAllRisksCover] = useState(false);
    const { handleError, handleNotBlockingError } = useErrorHandler();
    const { setIsQuoteLoadingView } = useContext(AppContext);
    const LOADING_VIEW_DISPLAY_TIME_MS = 2000;
    const [isAnyChange, setIsAnyChange] = useState(false);
    const translator = useContext(TranslatorContext);
    const { cleanNotUpdatedCoverages } = useCleanPayload();
    const {
        setIsNavigationDisabled,
        isQuoteEmailSent,
        setIsQuoteEmailSent,
        setCostInLastEmailSent
    } = useContext(NGHContext);
    const {
        initialValidation,
        onValidate,
        registerInitialComponentValidation
    } = useValidation('QuotePage');
    const {
        pushFormStepInfo,
        pushFormStepErrorInfo,
        pushLinkClickInfo,
        pushRelativeLinkClickInfo
    } = useTagManager();
    const isPhone = breakpoint === 'phone' || breakpoint === 'phoneWide';
    const currencyFormatter = useCurrency();
    const dynamicPricing = useMemo(
        () => _.get(submissionVM.value, 'quoteData.offeredQuotes[0].dynamicPricing_NFUM'),
        [submissionVM.value]
    );

    const getTotalCost = useCallback(() => {
        const paymentMethod = _.get(submissionVM.value, 'baseData.paymentMethod_NFUM');
        if (paymentMethod === 'directdebit') {
            const monthlyPremium = _.get(submissionVM,
                'quoteData.value.offeredQuotes[0].premium.monthlyPremium');
            return currencyFormatter.formatCurrency(monthlyPremium.amount, true);
        }
        const annualPremium = _.get(submissionVM,
            'quoteData.value.offeredQuotes[0].premium.total');
        return currencyFormatter.formatCurrency(annualPremium.amount, true);
    }, [submissionVM, currencyFormatter]);

    const sendQuoteEmailIfNeeded = useCallback(async () => {
        if (isQuoteEmailSent) return;
        try {
            await LoadSaveService
                .createQuoteDocs([submissionVM.quoteID.value, false], { authHeader });
            setIsQuoteEmailSent(true);
            setCostInLastEmailSent(_.get(
                submissionVM.value,
                'quoteData.offeredQuotes[0].premium.total.amount'
            ));
        } catch (e) {
            handleNotBlockingError(e);
        }
    }, [
        LoadSaveService,
        authHeader,
        submissionVM,
        setIsQuoteEmailSent,
        isQuoteEmailSent,
        setCostInLastEmailSent,
        handleNotBlockingError
    ]);

    const nonHEEligibleProperty = ['RentedFurnished', 'RentedUnfurnished'];

    useEffect(() => {
        const { isSkipping } = props;
        if (isSkipping) {
            initialValidation().then((skip) => {
                if (!skip) {
                    pushFormStepInfo(submissionVM, QB_STEPS.QUOTE);
                }
            });
        } else {
            pushFormStepInfo(submissionVM, QB_STEPS.QUOTE);
        }

        sendQuoteEmailIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSeeCoverClick = useCallback((url, coverName) => {
        pushLinkClickInfo(coverName, url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo]);


    const generateLegalExpensesCoverPath = useCallback(() => {
        const lobCoveragesPath = 'lobData.lexLine.lineCoverages.coverages.value';
        const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('LEXPersonalLegalExpensesCov');
        const lobPersonalLegalExpensesPath = generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)('Level of Cover');
        return lobPersonalLegalExpensesPath;
    }, [submissionVM]);

    const updateWizardDataAndSaveChangeInfo = useCallback((newSubmissionVM) => {
        setIsAnyChange(true);
        setIsNavigationDisabled(true);
        updateWizardData(newSubmissionVM);
    }, [setIsAnyChange, updateWizardData, setIsNavigationDisabled]);

    const isSubmissionQuotedOrBound = useCallback(() => {
        return ['Quoted', 'Bound'].includes(_.get(submissionVM.value, 'baseData.periodStatus'));
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(isSubmissionQuotedOrBound);
    }, [isSubmissionQuotedOrBound, registerInitialComponentValidation]);

    const handleRenderCell = useCallback((data, index, tableProps) => {
        const { path } = tableProps;
        const cellData = data[path];
        const CellRenderer = cellRenderers[_.toLower(cellData.valueType)] || Cell;
        return (
            <CellRenderer
                data={cellData}
                wizardData={submissionVM}
                updateWizardData={updateWizardDataAndSaveChangeInfo}
            />
        );
    }, [submissionVM, updateWizardDataAndSaveChangeInfo]);

    useEffect(() => {
        const sourceOfBusiness = submissionVM.sourceOfBusiness_NFUM.value?.code;
        const previousButtonValue = (sourceOfBusiness !== 'online');
        setDisablePreviousButton(previousButtonValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM.sourceOfBusiness_NFUM.value?.code]);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardDataAndSaveChangeInfo(newSubmissionVM);
        },
        [submissionVM, updateWizardDataAndSaveChangeInfo, viewModelService]
    );

    const quote = useCallback(async () => {
        try {
            setIsQuoteLoadingView(true);
            cleanNotUpdatedCoverages(submissionVM);
            const newSubmission = await LoadSaveService.saveAndQuoteSubmission(
                submissionVM.value,
                authHeader
            );
            submissionVM.value = newSubmission;
            setIsAnyChange(false);
            setIsNavigationDisabled(false);
            setIsQuoteLoadingView(false);
            return submissionVM;
        } catch (error) {
            pushFormStepErrorInfo(submissionVM, QB_STEPS.QUOTE, error);
            setIsQuoteLoadingView(false);
            handleError(error, submissionVM.value.quoteID);
            return false;
        }
    }, [
        LoadSaveService,
        authHeader,
        submissionVM,
        handleError,
        setIsQuoteLoadingView,
        pushFormStepErrorInfo,
        setIsNavigationDisabled,
        cleanNotUpdatedCoverages
    ]);

    const onPrevious = useCallback(async () => {
        if (isAnyChange) {
            return quote();
        }
        return submissionVM;
    }, [
        submissionVM,
        quote,
        isAnyChange
    ]);

    const onNext = useCallback(async () => {
        if (isAnyChange) {
            try {
                setIsQuoteLoadingView(true);
                cleanNotUpdatedCoverages(submissionVM);
                const saveAndQuotePromise = LoadSaveService.saveAndQuoteSubmission(
                    submissionVM.value,
                    authHeader
                );
                const someSecondsDelayPromise = new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, LOADING_VIEW_DISPLAY_TIME_MS); // time set to easily see the loader view
                });
                const results = await Promise.all([saveAndQuotePromise, someSecondsDelayPromise]);
                const [submissionVMResult] = results;
                submissionVM.value = submissionVMResult;
                setIsQuoteLoadingView(false);
                return submissionVM;
            } catch (error) {
                pushFormStepErrorInfo(submissionVM, QB_STEPS.QUOTE, error);
                setIsQuoteLoadingView(false);
                handleError(error, submissionVM.value.quoteID);
                return false;
            }
        } else {
            return submissionVM;
        }
    }, [
        LoadSaveService,
        authHeader,
        submissionVM,
        handleError,
        setIsQuoteLoadingView,
        isAnyChange,
        pushFormStepErrorInfo,
        cleanNotUpdatedCoverages
    ]);

    const contentsAccidentalCoverPath = generateAccidentalCoverPath(
        submissionVM,
        'HOMContentsCov',
        'HOMContentsCovAccidentalDamageCover'
    );
    const buildingsAccidentalCoverPath = generateAccidentalCoverPath(
        submissionVM,
        'HOMBuildingsCov',
        'HOMBuildingsCovAccidentalDamageRequired'
    );
    const bicycleExtensionPath = generateBicycleExtensionCoverPath(submissionVM);
    const propertyStatusPath = 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.propertyStatus';
    const isHomEmergencyCovAvailPath = 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.isHomEmergencyCovAvail';

    const handleAccidentalDamageCover = useCallback(
        (value, path) => {
            const accidentalDamageCoverPath = path === 'contentsAccidentalCover'
                ? contentsAccidentalCoverPath
                : buildingsAccidentalCoverPath;

            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, `${accidentalDamageCoverPath}.chosenTerm`, String(value));
            _.set(newSubmissionVM, `${accidentalDamageCoverPath}.chosenTermValue`, value ? 'Yes' : 'No');
            _.set(newSubmissionVM, `${accidentalDamageCoverPath}.updated`, true);
            _.set(newSubmissionVM, `${accidentalDamageCoverPath}.directBooleanValue`, value);
            updateWizardDataAndSaveChangeInfo(newSubmissionVM);
        }, [
            buildingsAccidentalCoverPath,
            contentsAccidentalCoverPath,
            submissionVM,
            updateWizardDataAndSaveChangeInfo,
            viewModelService
        ]
    );

    const handleEnableBicycleExtensionCover = useCallback(
        (value) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, `${bicycleExtensionPath.lobPedalCycleExtensionPath}.chosenTermValue`, value ? 'Yes' : 'No');
            _.set(newSubmissionVM, `${bicycleExtensionPath.lobPedalCycleExtensionPath}.updated`, true);
            _.set(newSubmissionVM, `${bicycleExtensionPath.lobCoveragePath}.updated`, true);
            _.set(newSubmissionVM, `${bicycleExtensionPath.lobPedalCycleExtensionPath}.directBooleanValue`, value);
            updateWizardDataAndSaveChangeInfo(newSubmissionVM);
        },
        [bicycleExtensionPath, submissionVM, updateWizardDataAndSaveChangeInfo, viewModelService]
    );

    const handleEnableHomeEmergencyCover = useCallback(
        (value) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomeYourDetails.isHomEmergencyCovAvail', value);
            updateWizardDataAndSaveChangeInfo(newSubmissionVM);
        },
        [submissionVM, updateWizardDataAndSaveChangeInfo, viewModelService]
    );

    const handleEnableStandardLegalExpensesCover = useCallback((isStandardChecked) => {
        if (!isLexAllRisksCover && !isStandardChecked) {
            _.set(submissionVM, 'lobData.homeLine.lexcoverLevel_NFUM', null);
            setIsLexStandardCover(false);
            setIsLexAllRisksCover(false);
        } else if (isStandardChecked === true) {
            _.set(submissionVM, 'lobData.homeLine.lexcoverLevel_NFUM', 'Standard');
            setIsLexStandardCover(true);
            setIsLexAllRisksCover(false);
        } else {
            _.set(submissionVM, 'lobData.homeLine.lexcoverLevel_NFUM', 'AllRisks');
            setIsLexStandardCover(false);
            setIsLexAllRisksCover(true);
        }
        updateWizardDataAndSaveChangeInfo(submissionVM);
    }, [
        submissionVM,
        updateWizardDataAndSaveChangeInfo,
        isLexAllRisksCover
    ]);

    const handleEnableAllRisksLegalExpensesCover = useCallback((isAllRisksChecked) => {
        if (!isLexStandardCover && !isAllRisksChecked) {
            _.set(submissionVM, 'lobData.homeLine.lexcoverLevel_NFUM', null);
            setIsLexStandardCover(false);
            setIsLexAllRisksCover(false);
        } else if (isAllRisksChecked === true) {
            _.set(submissionVM, 'lobData.homeLine.lexcoverLevel_NFUM', 'AllRisks');
            setIsLexStandardCover(false);
            setIsLexAllRisksCover(true);
        } else {
            _.set(submissionVM, 'lobData.homeLine.lexcoverLevel_NFUM', 'Standard');
            setIsLexStandardCover(true);
            setIsLexAllRisksCover(false);
        }
        updateWizardDataAndSaveChangeInfo(submissionVM);
    }, [
        submissionVM,
        updateWizardDataAndSaveChangeInfo,
        isLexStandardCover
    ]);

    useEffect(() => {
        const legalExpensesCoverPath = generateLegalExpensesCoverPath();
        const legalExpensesCoverValue = _.get(submissionVM, `${legalExpensesCoverPath}.chosenTermValue`);
        if (legalExpensesCoverValue && legalExpensesCoverValue === LEGAL_EXPENSES_STANDARD_KEY) {
            setIsLexStandardCover(true);
            setIsLexAllRisksCover(false);
        } else if (legalExpensesCoverValue
            && legalExpensesCoverValue === LEGAL_EXPENSES_EXTRA_KEY) {
            setIsLexAllRisksCover(true);
            setIsLexStandardCover(false);
        }
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isTierLevelThree = _.get(submissionVM, 'tierLevel_NFUM.value.code') === TIER_LEVEL_THREE_KEY;
    const contentsAccidentalCoverValue = _.get(submissionVM, `${contentsAccidentalCoverPath}.chosenTermValue`);
    const buildingsAccidentalCoverValue = _.get(submissionVM, `${buildingsAccidentalCoverPath}.chosenTermValue`);
    const bicycleExtensionValue = _.get(submissionVM, `${bicycleExtensionPath.lobPedalCycleExtensionPath}.chosenTermValue`);
    const propertyStatusValue = _.get(submissionVM, `${propertyStatusPath}.value.code`);
    const isHomEmergencyCovAvailValue = _.get(submissionVM, `${isHomEmergencyCovAvailPath}.value`, false);

    let excessTableData = [
        ...generateExcessTermRowData(submissionVM, 'Compulsory'),
        ...generateExcessTermRowData(submissionVM, 'Voluntary')
    ];
    let buildingsExcessTableData;
    let contentsExcessTableData;
    if (isPhone) {
        buildingsExcessTableData = [
            ...excessTableData,
            ...generateTotalExcessTermRowData(
                excessTableData, translator(messages.totalBuildingsExcess)
            )
        ];
        contentsExcessTableData = [
            ...excessTableData,
            ...generateTotalExcessTermRowData(
                excessTableData, translator(messages.totalContentsExcess)
            )
        ];
    } else {
        excessTableData = [
            ...excessTableData,
            ...generateTotalExcessTermRowData(
                excessTableData, translator(messages.excessTableTotalExcessColumn)
            )
        ];
    }

    const getContentsCovTerm = useCallback((termId) => {
        const homeLineCoverages = _.get(submissionVM, 'lobData.value.homeLine.lineCoverages.coverages');
        const contentsCov = homeLineCoverages?.find((cov) => cov.publicID === 'HOMContentsCov');
        return contentsCov?.terms?.find((term) => term.publicID === termId)?.chosenTermValue;
    }, [submissionVM]);

    const getBuildingsCovTerm = useCallback((termId) => {
        const homeLineCoverages = _.get(submissionVM, 'lobData.value.homeLine.lineCoverages.coverages');
        const buildingsCov = homeLineCoverages?.find((cov) => cov.publicID === 'HOMBuildingsCov');
        return buildingsCov?.terms?.find((term) => term.publicID === termId)?.chosenTermValue;
    }, [submissionVM]);

    const excessClaimTypesTableData = [
        {
            type: { value: translator(messages.leakingAndFrozenWater) },
            contentsExcess: { value: getContentsCovTerm('HOMContentsCovContentsEscapeOfWaterExcessTot'), valueType: 'money' },
            buildingsExcess: { value: getBuildingsCovTerm('HOMBuildingsCovEscapeOfWaterOrOilTotalExcess'), valueType: 'money' }
        },
        {
            type: { value: translator(messages.subsidence) },
            contentsExcess: { value: getContentsCovTerm('HOMContentsCovSubsidenceTotalExcess'), valueType: 'money' },
            buildingsExcess: { value: getBuildingsCovTerm('HOMBuildingsCovSubsidenceHeaveExcessTotal'), valueType: 'money' }
        },
        {
            type: { value: translator(messages.flood) },
            contentsExcess: { value: getContentsCovTerm('HOMContentsCovFloodTotalExcess'), valueType: 'money' },
            buildingsExcess: { value: getBuildingsCovTerm('HOMBuildingsCovFloodTotalExcess'), valueType: 'money' }
        }
    ];

    const highValueItemsTableData = generateHighValueItemsRowData(submissionVM);

    const getHomeEmergencyToggleName = () => {
        return _.get(submissionVM.value, isHomEmergencyCovAvailPath)
            ? translator(messages.removeCoverage.defaultMessage)
            : translator(messages.addCoverage.defaultMessage);
    };

    const getCycleProtectionToggleName = () => {
        return bicycleExtensionValue === 'Yes'
            ? translator(messages.removeCoverage.defaultMessage)
            : translator(messages.addCoverage.defaultMessage);
    };

    const getLEXExtraName = () => {
        return isLexAllRisksCover
            ? translator(messages.removeCoverage.defaultMessage)
            : translator(messages.addCoverage.defaultMessage);
    };

    const getLEXStandardName = () => {
        if (isTierLevelThree) {
            return translator(messages.includedAsStandard);
        }
        return isLexStandardCover
            ? translator(messages.removeCoverage.defaultMessage)
            : translator(messages.addCoverage.defaultMessage);
    };

    const getAccidentalDamageToggleName = () => {
        return buildingsAccidentalCoverValue === 'Yes'
            ? translator(messages.removeCoverage.defaultMessage)
            : translator(messages.addCoverage.defaultMessage);
    };

    const getContentsDamageToggleName = () => {
        if (isTierLevelThree) {
            return translator(messages.includedAsStandard);
        }
        return contentsAccidentalCoverValue === 'Yes'
            ? translator(messages.removeCoverage.defaultMessage)
            : translator(messages.addCoverage.defaultMessage);
    };

    const handleTypeOfPaymentChange = useCallback((paymentMethod) => {
        _.set(submissionVM.value, 'baseData.paymentMethod_NFUM', paymentMethod);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const handlePageNavigation = useCallback((typeOfLink) => {
        const indexOfPolicyDetails = _.findIndex(
            steps,
            ({ path }) => path === typeOfLink
        );
        jumpTo(indexOfPolicyDetails);
    }, [jumpTo, steps]);

    const getExtraDescription = useCallback(() => {
        const paymentMethod = _.get(submissionVM.value, 'baseData.paymentMethod_NFUM');
        if (paymentMethod === 'directdebit') {
            return messages.perMonth;
        }
        return messages.perYear;
    }, [submissionVM]);

    const getDynamicPrice = useCallback((amount) => {
        const paymentMethod = _.get(submissionVM.value, 'baseData.paymentMethod_NFUM');
        if (paymentMethod === 'directdebit') {
            return currencyFormatter.formatCurrency(amount / 12, true);
        }
        return currencyFormatter.formatCurrency(amount, true);
    }, [submissionVM, currencyFormatter]);

    const getDynamicPriceForLEXExtra = useCallback(() => {
        if (isTierLevelThree) {
            return getDynamicPrice(dynamicPricing.lexExtraPremium?.amount);
        }
        const allLexAmount = dynamicPricing.lexStandardPremium?.amount
            + dynamicPricing.lexExtraPremium?.amount;
        return getDynamicPrice(allLexAmount);
    }, [getDynamicPrice, dynamicPricing, isTierLevelThree]);

    const getPropertyAddress = useCallback(() => {
        const address = _.get(submissionVM, 'baseData.policyAddress.value');
        const titleParts = [
            address.addressLine1,
            address.addressLine2,
            address.addressLine3,
            address.city,
            address.postalCode
        ];
        return titleParts.filter((part) => part).join(', ');
    }, [submissionVM]);

    const seeAllCoverBenefits = translator(messages.seeAllCoverBenefits);
    const learnMore = translator(messages.learnMore);
    const toggleFieldRole = document.querySelectorAll('.jut__Button__button');
    toggleFieldRole.forEach((role) => {
        if (role) {
            role.setAttribute('role', 'button');
        }
    });

    const overrideProps = {
        enableHomeEmergencyCoverSwitch: {
            label: getHomeEmergencyToggleName(),
            description: getDynamicPrice(dynamicPricing.homeEmergencyPremium?.amount),
            extraDescription: getExtraDescription()
        },
        excessClaimTypesTable: {
            visible: breakpoint === 'desktop' || breakpoint === 'tablet',
            data: excessClaimTypesTableData
        },
        excessClaimTypesTableBuildingsColumn: {
            visible: hasCoverage(submissionVM, 'Buildings')
        },
        excessClaimTypesTableContentsColumn: {
            visible: hasCoverage(submissionVM, 'Contents')
        },
        excessClaimTypesMobileTableContainer: {
            visible: isPhone
        },
        buildingExcessClaimTypesTable: {
            visible: hasCoverage(submissionVM, 'Buildings'),
            data: excessClaimTypesTableData
        },
        contentsExcessClaimTypesTable: {
            visible: hasCoverage(submissionVM, 'Contents'),
            data: excessClaimTypesTableData
        },
        excessTable: {
            visible: breakpoint === 'desktop' || breakpoint === 'tablet',
            data: excessTableData
        },
        buildingExcessTable: {
            visible: hasCoverage(submissionVM, 'Buildings'),
            data: buildingsExcessTableData
        },
        contentsExcessTable: {
            visible: hasCoverage(submissionVM, 'Contents'),
            data: contentsExcessTableData
        },
        excessMobileTablesContainer: {
            visible: isPhone
        },
        excessTableBuildingsColumn: {
            visible: hasCoverage(submissionVM, 'Buildings')
        },
        excessTableContentsColumn: {
            visible: hasCoverage(submissionVM, 'Contents')
        },
        highValueItemsContainer: {
            visible: highValueItemsTableData.length > 0
        },
        highValueItemsTable: {
            data: highValueItemsTableData
        },
        accidentalDamageCoverContainer: {
            visible: !!contentsAccidentalCoverValue || !!buildingsAccidentalCoverValue
        },
        contentsAccidentalCoverContainer: {
            visible: !!contentsAccidentalCoverValue
        },
        enableContentsAccidentalCoverSwitch: {
            value: contentsAccidentalCoverValue === 'Yes',
            disabled: isTierLevelThree,
            label: getContentsDamageToggleName(),
            description: isTierLevelThree
                ? undefined
                : getDynamicPrice(dynamicPricing.contentsAccDamagePremium?.amount),
            extraDescription: isTierLevelThree ? undefined : getExtraDescription()
        },
        buildingsAccidentalCoverContainer: {
            visible: !!buildingsAccidentalCoverValue
        },
        enableBuildingsAccidentalCoverSwitch: {
            value: buildingsAccidentalCoverValue === 'Yes',
            label: getAccidentalDamageToggleName(),
            description: getDynamicPrice(dynamicPricing.buildingsAccDamagePremium?.amount),
            extraDescription: getExtraDescription()
        },
        enableStandardLegalExpensesCoverSwitch: {
            value: isLexStandardCover,
            label: getLEXStandardName(),
            disabled: isTierLevelThree,
            description: isTierLevelThree
                ? undefined
                : getDynamicPrice(dynamicPricing.lexStandardPremium?.amount),
            extraDescription: isTierLevelThree ? undefined : getExtraDescription()
        },
        enableEnhancedLegalExpensesCoverSwitch: {
            value: isLexAllRisksCover,
            label: getLEXExtraName(),
            description: getDynamicPriceForLEXExtra(),
            extraDescription: getExtraDescription()
        },
        bicycleExtensionCoverContainer: {
            visible: !!bicycleExtensionValue
        },
        enableBicycleExtensionCoverSwitch: {
            value: bicycleExtensionValue === 'Yes',
            label: getCycleProtectionToggleName(),
            description: getDynamicPrice(dynamicPricing.cycleProtectionPremium?.amount),
            extraDescription: getExtraDescription()
        },
        homeEmergencyCoverContainer: {
            visible: propertyStatusValue && !nonHEEligibleProperty.includes(propertyStatusValue)
        },
        homeEmergencyCoverTextClaimPeriod: {
            visible: isHomEmergencyCovAvailValue
        },
        updateQuote: {
            onClick: quote,
            visible: isAnyChange
        },
        costInfo: {
            content: getTotalCost(),
            visible: !isAnyChange
        },
        iptInfoContainer: {
            visible: !isAnyChange
        },
        propertyAddress: {
            content: getPropertyAddress()
        },
        typeOfPaymentToggle: {
            onValueChange: handleTypeOfPaymentChange,
            value: _.get(submissionVM.value, 'baseData.paymentMethod_NFUM')
        },
        editPropertyDetails: {
            onClick: () => {
                const url = '/your-details';
                pushRelativeLinkClickInfo(translator(messages.editPropertyDetails), url);
                handlePageNavigation(url);
            }
        },
        homeEmergencyCoverLink: {
            onClick: () => {
                onSeeCoverClick(homeEmergencyCoverUrl, seeAllCoverBenefits);
            }
        },
        contentsAccidentalCoverLink: {
            onClick: () => {
                onSeeCoverClick(contAccidentCovUrl, seeAllCoverBenefits);
            }
        },
        buildingsAccidentalCoverLink: {
            onClick: () => {
                onSeeCoverClick(buildingAccCovUrl, seeAllCoverBenefits);
            }
        },
        standardLegalExpensesCoverLink: {
            onClick: () => {
                onSeeCoverClick(standardLegalExpensesCoverUrl, seeAllCoverBenefits);
            }
        },
        enhancedLegalExpensesCoverLink: {
            onClick: () => {
                onSeeCoverClick(enhancedLegalExpensesCoverUrl, learnMore);
            }
        },
        bicycleExtensionCoverLink: {
            onClick: () => {
                onSeeCoverClick(cyclingProtectionCoverUrl, seeAllCoverBenefits);
            }
        },

    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRenderCell: handleRenderCell,
            onEnableAccidentalDamageCover: handleAccidentalDamageCover,
            onStandardLexCoverChange: handleEnableStandardLegalExpensesCover,
            onAllRisksLexCoverChange: handleEnableAllRisksLegalExpensesCover,
            onEnableBicycleExtensionCover: handleEnableBicycleExtensionCover,
            onEnableHomeEmergencyCover: handleEnableHomeEmergencyCover,
        },
        resolveComponentMap: {
            systemDownErrorMessage: SystemDownMessage
        }
    };

    return (
        <WizardPage
            showPrevious
            showCancel={false}
            skipWhen={initialValidation}
            nextLabel={commonMessages.continue}
            previousLabel={commonMessages.back}
            disablePrevious={disablePreviousButton}
            onPrevious={onPrevious}
            onNext={onNext}
            showNext
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
